.new-role__container {
  display: flex;
  gap: 20px;

  .new-role__card {
    padding: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    box-shadow: $shadow;

    h4 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 12px 0 20px;
    }
    button {
      letter-spacing: 1.25px;
      font-size: 14px !important;
      padding: 14px 24px;
    }
  }

  .new-role__card--buyer {
    background-color: $light-green;
  }
  .new-role__card--seller {
    background-color: $light-green;
  }
  .new-role__card--service__provider {
    h4 {
      color: $white;
    }
    background-color: $primary;
    color: $white;

    button {
      color: $white !important;
      border-color: $white !important;

      &:hover {
        background-color: $white !important;
        color: $primary !important;
      }
    }
  }

}
