.ant-pagination-prev:hover button, .ant-pagination-next:hover button {
  border-color: $primary-color;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: $primary-color;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: $primary;
}
