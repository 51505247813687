.product-attributes {
  margin-top: 40px;

  .product-attribute {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 0;
    border-bottom: 1px solid #EDEDED;

    .attr_name {
      width: 150px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: #AEAEAE;
    }

    .attr-options {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .option {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }

        a {
          margin-top: 8px;
          margin-bottom: 8px;
          border-radius: 4px;
          background: #F7F7F7;
          border: 1px solid #EDEDED;
          display: block;
          padding: 2px 8px;
          text-decoration: none !important;
          outline: none;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #808080;
        }
      }

      .active a {
        background: #DEFCEC;
        border: 2px solid #00968A;
        color: #000;
      }
    }
  }
}
