.contacts {

  .item {

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: 20px;
    }
  }

  .map {
    margin-top: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }
}
