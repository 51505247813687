.dropdown-menu {
  border-radius: 4px;
  box-shadow: 0px 3px 14px 0px #0000001f;
  margin: 0.6rem 0 0 0 !important;

  &__header-item {
    padding: 16px !important;
    background-color: white !important;

    &__head {
      display: flex !important;
      align-items: center !important;
      gap: 8px;
      margin-bottom: 4px;

      h4 {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
      }

      span {
        padding: 2px 4px !important;
        border-radius: 4px !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
        text-transform: capitalize;
        background-color: #defcec;
        color: #004d40;
      }
    }

    p {
      color: #4f4f4f;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 12px;
    }

    &__button {
      background-color: #69f0ae !important;
      color: #004d40 !important;
      border: none !important;
      border-radius: 5px;
      padding: 8px 65px;
      width: 100%;
      height: 32px !important;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;

      &:hover {
        background-color: #97fbcb !important;
      }
    }
  }

  &__item {
    width: 220px;
    padding: 16px 12px !important;

    &__link {
      display: flex !important;
      align-items: center !important;

      &__icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }

      &:hover {
        background-color: #f7f7f7;
      }
    }
  }
}
