.services-list__item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 12px;
  background-color: white;
  border: 1px solid #EDEDED;
  padding: 16px 12px;
  width: 462px;
  height: 104px;
  margin-bottom: 12px;
  cursor: pointer;

  svg {
    margin-top: 7px;
  }

  &__icon {
    width: 60px;
    height: 60px;
  }

  &__body {
    width: 338px;
    margin-top: 16px;

    &__title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__profile-info {
      color: #808080;
      font-size: 14px;
      margin-top: 3px;

      span {
        color: #004D40;
        font-weight: 700;
      }
    }
  }
}
