.ant-modal {
  position: relative !important;
  width: 68% !important;
  max-width: 951px !important;
  margin-right: 400px !important;

  &-content {
    width: 100% !important;
    border-radius: 8px !important;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-body {
    padding: 20px !important;
    max-width: 951px !important;
    border-radius: 8px !important;
  }

  &-close {
    position: absolute !important;
    left: 101% !important;

    &-x {
      background-color: #fff;
      border: 1px solid #004d40;
      border-radius: 4px;
      width: 32px !important;
      height: 32px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      svg {
        color: #004d40 !important;
        font-size: 16px !important;
      }
    }
  }
}

@media (max-width: 1252px) {
  .ant-modal {
    margin-right: 390px !important;
    width: 50% !important;
  }
}
