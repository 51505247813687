.photo-gallery__info {
  &__overlay {
    max-width: 200px;
    width: 100%;
    padding: 8px 12px;
    box-shadow: $shadow;
    border-radius: 8px;
    background-color: $white;
    text-wrap: wrap;
    font-size: 12px;
  }

  &__icon {
    margin-left: 4px;
    color: #aeaeae;
    transition: all ease-in 1s;

    :hover {
      color: #777;
    }
  }
}
