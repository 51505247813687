.products-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @import "filters";
  @import "nav";
  @import "products";

  @import "sellers-brands";
}
