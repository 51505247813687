.ant-popover {

  .ant-btn-sm {
    padding: 0 .5rem;
    font-size: .875rem;
    line-height: 24px;
    height: 26px;
    border-radius: .2rem;
    font-weight: 400;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #cccccc;
    border-color: #cccccc;
  }

  .ant-btn-dangerous {
    color: $danger-color;
    border-color: $danger-color;

    &:hover {
      background: $danger-color !important;
      color: #ffffff !important;
      border-color: $danger-color !important;
    }
  }
}
