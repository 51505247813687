@import './common/mixins';

// Colors
$primary-color: #004D40;
$secondary-color: #69F0AE;
$danger-color: #C30707;


/**
 * Colors
 */
$white: #fff;
$black: #141333;
$blue: #007be8;
$blue-light: #42baf9;
$blue-dark: #2c60e4;
$gray-0: #f2f4f980;
$gray-1: #f2f4f8;
$gray-2: #e4e8f1;
$gray-3: #d9dee9;
$gray-4: #c8c4db;
$gray-5: #a1a1c3;
$gray-6: #47525e;
$gray-7: #585a96;
$gray-8: #F7F7F7;
$gray-9: #808080;
$yellow: #ff0;
$orange: #ff993c;
$red: #eb5757;
$pink: #fd3995;
$purple: #652eff;
$green: #28a745;
$light-green: #B4F7D6;
$light-green-2: #E0F2F1;
$body-background: #f0f2f5;

$page-title: #4f4f4f;
$border-color: #e4e4e4;

$text: $gray-6;
$border: $gray-2;

// Accent colors
$default: $gray-4;

$primary: #004d40;
$secondary: #69f0ae;
$menu_side_hover: #69f0ae1a;

$black: #000;
$gray: #4f4f4f;
$light_gray: #bdbdbd;

$success: $secondary;
$info: $blue-light;
$warning: $orange;
$danger: $red;
$light: $gray-1;
$dark: $black;

// dark theme
$dark-gray-1: #aeaee0;
$dark-gray-2: #7575a3;
$dark-gray-3: #4f4f7a;
$dark-gray-4: #232135;
$dark-gray-5: #141322;
$dark-gray-6: #0c0c1b;

// Font Family
$base-font-family: 'Open Sans', 'Roboto', 'Arial', sans-serif;

// Font Size
$base-font-size: 14px;

// Shadows
$shadow: 0 0 40px -10px rgba($black, 0.2);
$shadow-2: 0 4px 38px 0 rgba($black, 0.11), 0 0 21px 0 rgba($black, 0.05);
$shadow-3: 0 0 100px -30px rgba(57, 55, 73, 0.3);
$shadow-4: 0 4px 10px 0 rgba($black, 0.03), 0 0 10px 0 rgba($black, 0.02);
$shadow-5: 0 0 40px -10px rgba($black, 0.4);
$shadow-6: 0 4px 10px 0 rgba(20, 18, 34, 0.028), 0 0 10px 0 rgba(20, 19, 33, 0.017);
$shadow-ant: 0 10px 35px -5px rgba(0, 0, 0, 0.15);

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // @return #{$px}px; // to PX's
}

// Transitions
@mixin transition-bg() {
  transition: background 0.2s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}

$default_transition: all 0.2s ease-in;

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$lg-min-width: 992px;
$lg-max-width: 991px;
$md-min-width: 768px;
$md-max-width: 767px;
$sm-min-width: 576px;
$sm-max-width: 575px;
