.add-to-cart {
  border: 1px solid #EDEDED;
  border-radius: 4px;
  margin-top: 28px;
  padding: 16px;

  .cart-nav {

    .price {

      .price-text {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.15px;
        color: #000000;
        text-align: left;

        > span {
          display: block;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.25px;
          text-decoration-line: line-through;
          color: #808080;
          font-weight: 400;
        }
      }

      .save {
        margin-left: 12px;
        margin-top: -2px;

        img {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.4px;
          color: #FD3F55;
        }
      }
    }

    .qty {

      .qty__wrapper {

        .wrap {

          input {
            padding: 0;
            height: 36px;
            border-radius: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            border: 1px solid #EDEDED !important;
            border-left: 0 !important;
            border-right: 0 !important;
            letter-spacing: 0.1px;
            color: #000000;
            padding-left: 10px;
            padding-right: 10px;
          }

          button {
            border: 1px solid #EDEDED;
            box-sizing: border-box;
            border-radius: 4px 0 0 4px;
            color: #808080;
            height: 36px;
            padding-left: 4px;
            padding-right: 4px;
            box-shadow: none !important;
            outline: none !important;

            svg {
              height: 16px;
              width: 16px;
            }

            .anticon {
              vertical-align: 1px;
            }
          }

          &:last-child {

            button {
              border-radius: 0px 4px 4px 0px;
            }
          }
        }
      }

      .qty-info {

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.4px;
          color: #808080;
          margin-bottom: 2px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .cart-btns {
    margin-top: 20px;

    button {
      width: 100%;
      border-radius: 4px;
      height: 44px;
      background: #004D40;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #FFFFFF;
      border: 2px solid #004D40;
    }

    .outlined {
      border: 2px solid #004D40;
      background: transparent;
      color: #004D40;
    }
  }

  .card-extras {
    margin-top: 24px;

    .card-extra {

      .anticon {
        vertical-align: 0;
      }

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #808080;
      }
    }
  }
}
