.explore-header {

  h1 {
    margin-top: 0;
    margin-bottom: 28px;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    color: #000000;
  }

  .explore-nav {

    ul {
      margin: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #EDEDED;
      padding: 0 0 12px;
      width: 100%;

      li {
        margin-right: 60px;

        a {
          display: block;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #808080;
          outline: none;
          text-decoration: none;
        }
      }

      .active {

        a {
          color: #004D40;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -12px;
            width: 100%;
            height: 4px;
            background: #004D40;
          }
        }
      }
    }
  }

  .grid-list {
    margin-top: 24px;

    .order {
      cursor: pointer;

      .icon {
        margin-right: 12px;

        svg {
          height: 24px;
          width: 24px;
        }
      }

      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #AEAEAE;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      li {
        cursor: pointer;

        &:first-child {
          margin-right: 12px;
        }

        svg {
          width: 24px;
          height: 24px;

          path {
            fill: #AEAEAE;
          }
        }
      }

      .active svg path {
        fill: #004D40;
      }
    }
  }
}
