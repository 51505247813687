.carousel {

  .item {
    width: 140px !important;
    height: 140px;
    user-select: none;

    .itemWrapper {
      width: 100%;
      height: 100%;
      position: relative;
      border: 1px solid #EDEDED;
      box-sizing: border-box;
      overflow: hidden;

      img, svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
      }

      .image {
        width: 280px;
      }

      svg {
        top: 40%;
        width: 70px;
        height: 70px;
      }

      p {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 3px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding: 0 8px;
        letter-spacing: 0.4px;
        color: #4F4F4F;

        a {
          text-decoration: none;
        }
      }

      > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        > div svg {
          top: 35%;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
