.info-icon {
  color: #ccc;
  transition: color ease-in 1.25s;

  :hover {
    color: $primary;
  }

  &__overlay {
    padding: 12px 4px;
    background-color: $white;
    border-radius: 4px;

    p {
      margin: 0;
      padding: 0;
    }

    &--missing-data {
      margin-top: 8px;
      background-color: #00000099;
      color: $white;
      font-size: 12px;
      text-align: center;

      ul {
        margin: 0;
        padding: 0;
      }
    }

    &--usp {
      background-color: #00000099;
      color: $white;
      padding: 8px 12px;
    }
  }
}
