.progress-bar__container {
  height: 12px;
  border-radius: 6px;
  width: 100%;
  background-color: $gray-8;


  .progress-bar__filler {
    background-color: $primary;
    height: 100%;
    border-radius: inherit;
    max-width: 100%;
    transition: all ease 200ms;
  }
}
