.products-upload-packaging-form {

  .packaging-form {

    .ant-radio-button-wrapper {
      height: auto;
      margin-bottom: 30px;
      text-align: center;
      padding: 20px 40px;
      color: #000000;

      .packaging-form__radio {

        .radioIcon {
          margin-bottom: 12px;

          img {
            height: 60px;
            width: auto;
          }
        }

        .radioName {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
        }
      }
    }

    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border: 2px solid $primary;
      color: $primary;
    }
  }
}

.packagingTypesVariants {

  .packagingTypesVariant {

    label {
      background: #DEFCEC;
      border: 1px solid #EDEDED;
      box-sizing: border-box;
      border-radius: 4px;
      height: auto;
      text-align: center;
      margin-bottom: 30px;
      padding: 20px 40px;

      .packagingTypesVariantsWrapper {

        .packagingTypesVariantIcon {

          img {
            height: 60px;
            width: auto;
          }
        }
      }
    }

    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border: 2px solid $primary;
      color: $primary;
    }
  }
}

@media screen and (max-width: 1480px) {
  .products-upload-packaging-form {

    .packaging-form {

      .ant-radio-button-wrapper {
        padding: 10px;

        .packaging-form__radio {

          .radioIcon {

            img {
              height: 40px;
              width: auto;
            }
          }

          .radioName {
            height: 15px;
            overflow: hidden;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
  }

  .packagingTypesVariants {

    .packagingTypesVariant {

      label {
        padding: 10px;

        .packagingTypesVariantsWrapper {

          .packagingTypesVariantIcon {

            img {
              height: 40px;
              width: auto;
            }
          }
        }
      }
    }
  }
}
