.products-upload {
  .ant-tabs {
    overflow-y: visible;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  &__return-link {
    a {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 600;
      font-size: 14px;
      color: #26a69b;
    }
  }

  &__section-title {
    font-weight: 700;
    font-size: 24px;
    color: #004d40;
    margin-bottom: 1.8rem;
  }

  .variant-controls {
    min-width: 12rem;
    padding: 8px;

    &__wrapper {
      margin-left: 18px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .variant-price {
        font-weight: 700;
        font-size: 18px;
        color: #004d40;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.6rem;

      svg {
        width: 0.8rem;
        margin-right: 0.2rem;
      }

      span {
        font-size: 12px;
        color: #4f4f4f;
      }

      button {
        width: 2.8rem !important;
        flex-grow: 1;
        line-height: 18px !important;
        span {
          color: #004d40 !important;
        }
      }
    }
  }
}

.product-upload__packages {
  &__helper {
    color: $primary-color;
    font-weight: normal;
  }
  .package-type {
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    min-height: 450px;
    z-index: 1;

    .ant-checkbox {
      position: absolute;
      left: 16px;
      top: 19px;
      z-index: 5;
    }

    .ant-checkbox + span {
      padding: 0;
    }

    .type__head {
      background: #e0f2f1;
      border-radius: 3px 3px 0px 0px;
      height: 56px;
      line-height: 56px;
      position: relative;
      z-index: 2;

      .head__title {
        position: absolute;
        left: 48px;
        top: 50%;
        transform: translateY(-50%);
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #004d40;
        padding-right: 16px;
      }
    }

    .type__icon {
      padding: 16px 0;
      text-align: center;
    }

    .type__description {
      padding: 0 16px 16px;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }
  }
}

.incomplete-data {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  min-width: 504px;

  &__item {
    padding: 4px 12px;
    border-radius: 4px;
    background-color: $gray-2;

    p {
      margin-bottom: 0px;
      color: $primary-color;
      font-size: 14px;
    }
  }
}
