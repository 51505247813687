.shipping-and-services-form {
  .ant-form-item {
    display: block !important;

    &-no-colon {
      font-size: 16px;
      font-weight: 700;
      color: #000000 !important;
      margin-bottom: 16px;
    }
  }
}
