.seek-trade-support {
  margin-top: 30px;

  h3 {
    margin-top: 0;
    margin-bottom: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
  }

  .stsWrapper {
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px;

    .stsIcon {
      margin-right: 12px;

      svg {
        height: 24px;
        width: auto;
      }
    }

    .stsName {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #000000;
    }

    .stsAdd {
      width: 32px;
      height: 32px;
      background: #DEFCEC;
      border-radius: 16px;
      text-align: center;
      padding: 2px 4px;
      cursor: pointer;
    }
  }
}
