@import '../../../../scss/variables';

.dashboard-modal {
  .ant-modal-close-x {
    color: #CCCCCC;
  }

  .ant-modal-body {
    padding: 0px !important;
  }

  .ant-modal-content {
    border-radius: 12px;
  }

  &__modal-body {
    padding: 40px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 14px;
    margin-bottom: 20px;
  }

  &__list {
    padding: 0;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // gap: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    gap: 10px;
    min-height: 280px;


    &:has(.spin-loader) {
      align-items: center;
    }


    &__item, &__item--active {
      padding: 12px 4px;
      border: 1px solid #CCCCCC;
      width: 256px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 2px 0;

      &--active {
        border: 3px solid $primary-color;
        border-radius: 4px;
        margin: 0;
      }

      &__icon {
        margin-right: 12px;
      }

      &__text {
        margin: 0;
      }

      &:hover {
        box-shadow: 0px 2px 4px 0px #0000001A;
      }
    }
  }

  &__modal-footer {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;

    .modal-button {
      width: 108px;
      height: 44px;
    }

    &__span {
      color: $primary-color;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      position: absolute;
      bottom: 20px;
      right: 24px;
      cursor: pointer;

      &:hover {
        color: $green;
      }
    }
  }
}
