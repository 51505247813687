.settings {
  &__auth {
    &__change-pwd-form {
      display: flex;
      flex-wrap: wrap;
      background-color: $white;
      padding: 1.2rem 2rem;
      border: 1px solid #ededed;
      border-radius: 4px;

      .default-page-title {
        width: 100%;
      }

      .ant-form {
        width: 100%;
        display: flex;
        align-items: end;

        &-item {
          max-width: 100%;
          width: 100%;
          margin: 0 2rem 0 0;

          &-label {
            padding: 0 0 1rem;
          }
        }
        .ant-btn {
          height: 44px;
          min-width: 12.75rem;
          letter-spacing: 1.2px;
        }
      }
    }
  }
}
