.tutorial-button--hidden {
  display: none;
}

.tutorial-button {
  position: absolute;
  z-index: 2;
  right: 15px;
  top: 25px;

  .play-icon {
    font-size: 16px;
  }

  .ant-btn {
    display: flex;
    align-items: center;
    gap: 12px;
    max-height: 36px;
    max-width: 120px;
    padding: 8px 13px;

    span {
      font-size: 12px;
      margin: 0 !important;
    }

    svg {
      font-size: 16px;
    }
  }
}
