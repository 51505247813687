.breadcrumb {
  &__item {
    color: #aeaeae !important;
    text-transform: capitalize;
  }

  span:last-child {
    .breadcrumb__item {
      color: #004d40 !important;
    }
  }
}
