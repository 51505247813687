.profile-status-card {
  background-color: $white;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  box-shadow: $shadow-6;

  h4 {
    font-weight: 700;
    font-size: 16px;
  }

  p {
    color: $gray-9;
    font-size: $base-font-size;
    margin-bottom: 0;
    font-weight: 400;

    span {
      font-weight: 700;
      color: $primary;
    }
  }

  .profile-status-card__complete {

    a {
      color: $primary;
      font-size: 14px;
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 5px;

      i {
        color: $primary;
        font-weight: 700;
        font-size: 18px;
      }
    }

  }

}
