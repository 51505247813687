.sales-term {

  .anticon-minus {
    height: 100%;
    svg {
      height: 100%;
    }
  }

  &--extra {
    position: relative;
    padding: 20px;
    background-color: $white;
    border-radius: 4px;

    & > .fe-trash {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

  }
}
