.card {
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  position: relative;

  .card-edit {
    position: absolute;
    right: 23px;
    top: 23px;
    text-decoration: none;
    color: #CCCCCC;
    font-size: 20px;
  }

  .card__head {
    margin-bottom: 24px;

    .card__title {
      margin-top: 0;
      margin-bottom: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #000000;
    }
  }
}
