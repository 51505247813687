.product-prices {
  margin-top: 40px;

  .prices-select {

    .icon {

      img {
        height: 24px;
        width: 24px;
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 0 !important;
    }
  }

  .prices {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .price-item {
      margin-right: 8px;
      background: #F7F7F7;
      border: 1px solid #EDEDED;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 7px 16px;
      user-select: none;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      .items {
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #CCCCCC;
        margin-bottom: 7px;
      }

      .price {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #808080;
        margin-bottom: 7px;
      }

      .price-sub {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #808080;
      }
    }

    .price-item__active {
      background: #DEFCEC;
      border: 2px solid #00968A;

      .items {
        color: #808080;
      }

      .price,
      .price-sub {
        color: #000000;
      }
    }
  }
}
