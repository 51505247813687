.salesPresenceData {
  margin-bottom: 42px;

  .item {

    .icon {
      margin-right: 24px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: #808080;
      margin-bottom: 8px;
    }

    .title {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      color: #004D40;
    }
  }
}

.mapData {
  margin-top: 40px;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 28px;
  }
}
