.sellerShowroom {
  margin-top: -28px;

  .sellerShowroom__head {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 170px 0;
    background-color: $primary;
    position: relative;

    .sellerShowroom__head-uploadWrapper {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      box-shadow: $shadow-ant;

      .upload {
        position: relative;
        z-index: 1;

        input[type="file"] {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          opacity: 0;
        }

        .uploadIcon {
          background: #FFF;
          padding: 8px;
          border-radius: 50%;

          svg {
            width: 26px;
            height: 26px;
          }
        }
      }
    }

    h1 {
      text-align: left;
      margin-top: 0;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      color: #FFFFFF;
      text-shadow: 0 0 1px #000;
    }

    h4 {
      text-align: left;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 33px;
      color: #FFFFFF;
      text-shadow: 0 0 1px #000;
    }
  }

  .sellerShowroom__title {
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .logo {
      margin-right: 16px;

      img {
        height: 64px;
        width: auto;
      }
    }

    .name {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: #004D40;
    }
  }

  .sellerShowroom__body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .companyInfo {
      flex-basis: 292px;
      margin-right: 28px;
    }

    .sellerShowroom__products {
      flex-basis: calc(100% - 320px);
    }
  }

  .sellerShowroom__left {
    background: #F7F7F7;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;

    .sellerShowroom__viewCompanyFacts {
      display: block;
      width: 100%;
      margin-top: 20px;
      text-align: center;
      text-decoration: none;
      padding: 14px;
      border: 2px solid #004D40;
      border-radius: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #004D40;
      transition: all .3s ease;

      &:hover {
        background: #004D40;
        color: #FFF;

        svg {
          path {
            fill: #FFF;
            transition: all .3s ease;
          }
        }
      }
    }

    .sellerShowroom__card {
      padding: 20px;
      background: #FFFFFF;
      box-shadow: 0px 2.68757px 5.37514px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .sellerShowroom__title {
        margin-top: 0;
        margin-bottom: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
      }

      .sellerShowroom__location {

        img {
          max-width: 100%;
          height: auto;
        }
      }

      .sellerShowroom__info {

        .sellerShowroom__infoWrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: nowrap;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #4F4F4F;
            margin-bottom: 0 !important;

            strong {
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 33px;
              color: #004D40;
            }
          }
        }
      }

      .sellerShowroom__categories {

        .sellerShowroom__category {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: nowrap;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          .sellerShowroom__categoryIcon {
            width: 40px;
            height: 40px;
            flex: 0 0 40px;
            margin-right: 20px;
            text-align: center;
            padding: 6px;
            background: #DEFCEC;
            border-radius: 20px;

            img {
              height: 28px;
              width: auto;
            }
          }

          .sellerShowroom__categoryName {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #4F4F4F;
          }
        }
      }
    }
  }
}
