// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
// Antd
@import '~antd/dist/antd.css';
// Bootstrap
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/utilities/api';
// Quill
@import '~react-quill/dist/quill.core.css';
@import '~react-quill/dist/quill.snow.css';
// App styles
@import 'variables';
// Common
@import 'common/common';
body {
  // Styles
  @import 'common/form';
  @import 'common/buttons';
  @import 'common/table';
  @import 'common/type';
  @import 'common/date-picker';
  @import 'common/icons';
  // Components
  @import 'components/modal';
  @import 'components/page-title';
  @import 'components/card';
  @import 'components/quill';
  @import 'components/file-uploader';
  @import 'components/popover';
  @import 'components/pagination';
  @import 'components/jvector-map';
  @import 'components/ant-menu';
  @import 'components/carousel';
  @import 'components/tabs';
  @import 'components/progress-bar';
  @import 'components/profile-status-card';
  @import 'components/color-picker';
  @import 'components/tag-list';

  // Layout
  @import 'layout/layout';
  @import 'layout/product-upload';
  @import 'layout/new-role';
  @import 'layout/profile-completion';
  // Pages
  @import 'pages/onboarding/onboarding';
  @import 'pages/company-profile';
  @import 'pages/products-uploads';
  @import 'pages/products-upload/packaging-form';
  @import 'pages/products-upload/product-details';
  @import 'pages/products-upload/sales-term';
  @import 'pages/products-upload/product-information';
  @import 'pages/products-upload/product-attributes';
  @import 'marketplace/index';
  @import 'marketplace/explore/index';
  @import 'pages/products';
  @import 'pages/dashboard/index';
  @import 'pages/index';
  // Responsive
  @import 'responsive/index';
}

a {
  text-decoration: none;
}
