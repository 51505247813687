.ant-picker {
  padding: 10px 20px;
  color: #000;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  width: 100%;
}

.ant-picker-input > input {
  color: #000;
  font-size: 16px;
  line-height: 20px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: $primary-color;
}

.ant-picker-focused {
  border-color: $primary-color;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: $primary-color;
  border-right-width: 1px !important;
}
