.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white !important;
  height: 56px !important;

  .ant-page-header {
    &-heading {
      display: flex !important;
      align-items: center !important;
      align-items: center;
      justify-content: space-between;
    }

    &-content {
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
}

