.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary;
}

.ant-tabs-tab .ant-tabs-tab-btn{
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #808080;
}

.ant-tabs-tab:hover {
  color: $primary;
}

.ant-tabs-ink-bar {
  background: $primary;
}
