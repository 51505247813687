.jvectormap-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  touch-action: none;
}

.export-countries-map {

  .export-countries__tags {

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        background: #E0F2F1;
        border-radius: 4px;
        height: 32px;
        position: relative;
        overflow: hidden;
        width: fit-content;


        .export-countries__item {
          padding: 8px;
          padding-right: 0px;
          position: relative;
          width: 100%;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        .close {
          cursor: pointer;
          width: 18px;
          flex: 0 0 18px;
          height: 18px;
          text-align: center;
          font-size: 14px;
          line-height: 18px;
          color: #E0F2F1;
          background: $primary;
          border-radius: 18px;
          margin-left: auto;
        }

        .flag {
          margin-right: 8px;
          margin-top: -4px;

          img {
            height: 12px;
            width: auto;
          }
        }

        .name {
          margin-right: 8px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $primary;
          letter-spacing: 0.1px;
          white-space: nowrap;
        }
      }
    }
  }

  &__tags .export-countries__tags {

    ul {

      li {
        background: #E0F2F1;
        border-radius: 4px;
        padding: 8px;
        height: auto;
        position: relative;
        overflow: hidden;

        .export-countries__item {
          padding: 8px;
          position: relative;
          width: 100%;
          top: 0;
          left: 0;
          transform: translateY(0);
        }

      }
    }
  }
}
