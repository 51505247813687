@media screen and (max-width: 1024px) {

  .verification-layout .layout-content .verification-nav {
    display: none;
  }

  .verification-layout .layout-content .main-content {
    width: 100%;
  }

  .verification-layout .company-logo .logo__upload {
    height: 240px;
  }

  .verification-layout .layout-content .main-content .content-container {

    .sub-industries__wrapper {

      .industry__label {
        display: flex;
        align-items: center;

        .industry__icon {
          margin-right: 10px;

          img {
            height: 40px;
            width: 40px;
          }
        }

        .industry__name {
          margin-top: -4px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }
      }
    }
  }

  .verification-layout .brand-item .brand-logo-name {
    font-size: 14px;
  }

  .verification-layout .brand-item .upload-link {
    font-size: 12px;
  }
}
