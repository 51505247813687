.product-details {
  &__section-title {
    font-size: 16px;
    margin-bottom: 16px !important;
    margin-top: 24px;
  }


  &__radio-group {
    margin-bottom: 18px;

    .ant-radio-wrapper {
      margin-right: 40px;
    }
  }

}
