@import "layout";

.product-details {

  .product-details__wrapper {
    padding-bottom: 40px;
  }

  @import "product-gallery";
  @import "product-title";
  @import "product-manufacturer";
  @import "product-description";
  @import "product-attributes";
  @import "product-prices";
  @import "add-to-cart";
  @import "products-tags";
  @import "seek-trade-support";
}

@import "seller-showroom";

.product-details__vertical {

  .product-gallery {
    max-height: 535px;

    .ant-carousel img {
      height: auto;
      max-width: 100%;
    }
  }

  .subtitle {
    max-width: 360px;

    .d-flex {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .add-to-cart {
    background: #FFF;
  }

  .product-manufacturer__vertical {

    .product-manufacturer {
      padding: 16px;
      width: 100%;
      border: 1px solid #EDEDED;
      border-radius: 4px;
      background: #FFF;
    }
  }
}
