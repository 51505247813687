.footer {
  background: #004D40;
  margin-top: 40px;
  padding: 60px 0;

  .downloadApp {
    text-align: center;

    h4 {
      margin-top: 0;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #B4F7D6;
    }
  }

  .social {
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 28px;

    li {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: #ffffff;
        font-size: 32px;
      }
    }
  }

  .copy {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #FFFFFF;
  }
}
