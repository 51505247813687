.video-container {
  position: relative;
}

.custom-video-js {
  width: 100%;

  &:hover {
    .vjs-big-play-button {
      background-color: #fff;
    }
  }

  .vjs-big-play-button {
    background-image: url('./assets/icons/play.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 56%;
    background-size: 40%;

    position: absolute;
    left: 56%;
    color: #004d40;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 75px;
    width: 120px;
    height: 120px;
    &:hover {
      background-color: #ccc;
    }

    .vjs-icon-placeholder {
      display: none;
    }
  }
}

@media (max-width: 1251px) {
  .custom-video-js {
    .vjs-big-play-button {
      left: 62%;
    }
  }
}
