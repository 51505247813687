.description-card {
  background: #FFF;
  padding: 24px 40px;
  border: 1px solid #EDEDED;

  .card-title {
    margin-top: 0;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
  }

  p, ul li, ol li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    margin-bottom: 10px;

    strong {
      display: contents;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__packaging {

    .product-details__packages {

      .package-item {
        padding-bottom: 5px;
        margin-bottom: 17px;
        border-bottom: 1px solid #EDEDED;

        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }

        .package-item__head {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;

          .package-icon {
            margin-right: 8px;

            img {
              height: 24px;
              width: auto;
              margin-top: -2px;
            }
          }

          .package-name {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #000000;

            span {
              padding-left: 6px;
              color: #26A69B;
            }
          }
        }

        .package-item__content {

          p {
            margin-bottom: 12px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #808080;

            span {
              padding-left: 12px;
              color: #000000;
            }
          }
        }
      }
    }
  }
}

.product-description {
  background: #F7F7F7;
  padding: 40px 0;
}

.product-details__table {
  width: 100%;

  tbody {

    tr {

      &:nth-child(2n) {
        background: #F7F7F7;
      }

      td {
        border-top: 0;
        padding: 6px 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        letter-spacing: 0.1px;
        color: #000000;

        &:last-child {
          border-left: 2px solid #FFF;
        }
      }
    }
  }
}
