.ant-input {
  padding: 10px 20px;
  color: #000;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  height: 42px;
}

.ant-form-item-label > label {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #4F4F4F;
  width: 100%;
}

.ant-input:hover {
  border-color: $primary-color;
}

.ant-input:focus, .ant-input-focused {
  border-color: $primary-color;
  box-shadow: none;
}

.ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: $danger-color;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: $danger-color;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #CCCCCC;
  height: 44px;
  border-radius: 4px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 44px;
  color: #000;
  font-size: 16px;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  color: #bbb;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $primary-color;
  border-right-width: 1px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $primary-color;
  box-shadow: none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #B2DFDB;
  color: #000;
}

.ant-checkbox-inner {
  border: 1px solid #CCCCCC;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color;
  border-color: $primary-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary-color;
}

.ant-checkbox-checked::after {
  border: 1px solid $primary-color;
}

.ant-radio {
  color: #000;
  font-size: 14px;
}

.ant-radio-inner {
  border-color: #ccc;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $primary-color;
}

.ant-radio-inner::after {
  background-color: $primary-color;
}

.ant-input-affix-wrapper {
  padding: 10px 20px;
  color: #000;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border: 1px solid $primary;
  outline: 0;
  box-shadow: none;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid $primary;
}

.placeholder-sm {
  &::placeholder {
    font-size: 12px;
  }
}
