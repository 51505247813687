.file-uploader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .file-preview {

    .preview {

      img {
        max-width: 180px;
        height: auto;
      }
    }

    .preview-text {
      margin-top: 19px;

      p {
        margin-bottom: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #808080;
      }
    }
  }

  .uploader__wrapper {
    margin-right: 30px;
    width: 400px;
    height: 400px;
    position: relative;
    z-index: 1;
    border: 3px dashed #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;

    .input-file {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 3;
      cursor: pointer;
    }

    .uploader__content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 162px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.1px;
      cursor: pointer;

      span {
        font-weight: 600;
        color: $primary-color;
      }
    }
  }
}

.add-other-images {
  position: relative;
  margin-top: 24px;
  z-index: 1;

  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 3;
    cursor: pointer;
  }

  .file-upload-text {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #004D40;
    z-index: 2;
    position: relative;
  }
}

.images-list {

  .image-wrapper {
    border: 1px dashed #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .remove-image {
      z-index: 6;
      position: absolute;
      right: 12px;
      top: 12px;
      background: #FFF;
      padding: 5px 12px;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: auto;
      z-index: 4;
    }
  }
}

.image-fluid {
  max-width: 100%;
  height: auto;
}
