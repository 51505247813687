.products-upload {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;

  .upload-content {
    width: calc(100% - 400px);

    h1 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.15px;
      color: $primary-color;
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  .upload-navbar {
    position: fixed;
    right: 0;
    top: 0;
    width: 367px;
    height: 100%;
    background: #fff;
    z-index: 3;

    .navbar__wrapper {
      position: absolute;
      left: 50%;
      bottom: 124px;
      transform: translateX(-50%);
      z-index: 4;
      width: calc(100% - 80px);

      h3 {
        margin-top: 0;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #004d40;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #4f4f4f;
          margin-bottom: 12px;
          position: relative;
          padding-left: 16px;

          &:before {
            padding-left: 4px;
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 4px;
            height: 4px;
            border-radius: 4px;
            background: #4f4f4f;
          }

          &:last-child {
            margin-bottom: 0;
          }

          span {
            cursor: pointer;
          }
        }

        .active {
          color: $primary-color;
          font-weight: 600;

          &:before {
            background: $primary-color;
          }
        }
      }
    }
  }

  .upload-controls {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    box-shadow: 8px 0px 20px rgba(0, 0, 0, 0.12);
    height: 74px;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 24px;

    button {
      display: block;
    }

    .next-prev-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      i,
      span {
        font-weight: 700;
      }
    }

    .save-button-wrapper {
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
    }

    .prev-btn,
    .next-btn {
      padding: 0;
      margin: 0 24px;
      border: 0;
      outline: none;
      box-shadow: none;
      color: $primary-color;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.25px;
      position: relative;

      i {
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .prev-btn {
      //left: 24px;
      //top: 50%;
      //transform: translateY(-50%);

      i {
        left: -15px;
      }
    }

    .next-btn {
      //left: calc(100% - 160px);
      //top: 50%;
      //transform: translateY(-50%);
    }

    button {
      //position: absolute;
      //left: 50%;
      //top: 50%;
      //transform: translate(-50%, -50%);
      position: relative;
      z-index: 6;
    }
  }
}

.product-variants {
  .product-variant {
    padding: 12px 20px;
    background: #fff;
    border-radius: 4px;

    .variant-image {
      flex: 0 0 144px;
      width: 144px;
      margin-right: 24px;
    }

    .variant-details {
      width: 80%;
      padding: 12px;

      .variant-name {
        margin-right: 12px;
        margin-top: 0;
        margin-bottom: 0;
        flex-grow: 3;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }

      .variant-price {
        p {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #00968a;
        }
      }

      .product-description {
        flex-grow: 2;

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #000000;

          span {
            font-weight: 300;
            color: #808080;
          }
        }
      }
    }

    &--master {
      background-color: #defcec;
      border: 1px solid #EDEDED
    }
  }
}
