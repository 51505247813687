.ant-carousel {
  padding-bottom: 40px;
}

.ant-carousel .slick-prev, .ant-carousel .slick-next {
  top: calc(100% + 20px);
}

.ant-carousel .slick-prev {
  left: 43%;
}

.ant-carousel .slick-next {
  right: 46%;
}
