.packagesVariants {

  .packageVariant {
    padding: 20px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .headWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .titleWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;

        .icon {
          margin-right: 12px;

          img {
            height: 36px;
            width: auto;
          }
        }

        .name {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #000000;
        }
      }
    }

    .content {

      p {
        margin-top: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #808080;
        margin-bottom: 0;

        strong {
          font-weight: 700;
          color: #000000;
        }
      }
    }
  }
}
