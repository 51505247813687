.product-manufacturer {

  .brand-logo {
    border-radius: 50px;
    overflow: hidden;
    margin-right: 11px;
    border: 1px solid #EDEDED;

    img {
      height: 50px;
      width: 50px;
      filter: grayscale(100%);
      opacity: .4;
    }
  }

  .brand-info {

    h3 {
      margin-top: 0;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 20px;

      a {
        font-weight: 600;
        color: #004D40;
        text-decoration: none;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    .address {
      margin-bottom: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #808080;
    }

    .brand-rating {
      margin-top: 8px;
      height: 20px;
      overflow: hidden;

      .ant-rate-star-first .anticon, .ant-rate-star-second .anticon {
        vertical-align: 6px;
      }

      .ant-rate-star:not(:last-child) {
        margin-right: 4px;
      }

      .anticon svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  &:hover {

    .brand-logo {

      img {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }
}
