.products--unverified {
  p {
    padding-top: 12px;
  }

  button {
    display: flex;
    align-items: center;


    height: 44px;
    margin-top: 32px;

    i {
      font-weight: 700;
      font-size: 16px;
      margin-left: 10px;
    }
  }
}
