.verification-modal {
  .ant-modal-content {
    border-radius: 5px;
    padding: 24px;
  }

  .ant-modal-header {
    border-bottom: none;
    padding: 0px;
    margin-bottom: 16px;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-footer {
    margin-top: 20px;
    border-top: none;
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }

  p {
    color: $black;
    letter-spacing: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 0px;
  }

  button {
    height: 44px;
  }
}

.interested-industries-modal {
  border-radius: 12px;

  .ant-modal-title {
    text-align: left;
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: $primary;
      margin-top: 20px;
      margin-bottom: 16px;
    }
    p {
      text-align: left !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  .ant-modal-header {
    border-bottom: none;
    margin-bottom: 16px;
    border-radius: 12px;
  }
  .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-body {
    overflow-y: scroll;
    scrollbar-width: 0px;
    max-height: 468px;
    padding: 0 20px;
  }

  .ant-modal-footer {
    margin-top: 20px;
    border-top: none;
    box-shadow: $shadow;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        margin-left: 72px;
      }

      p {
        color: $primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
        margin-bottom: 0px;
      }
    }
  }

  .company__industries {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: center;
    width: 100%;

    .industry-item {
      width: 151px;
      height: 144px;
      margin: 4px;
      user-select: none;
      cursor: pointer;

      .industry__wrapper {
        width: 151px;
        height: 144px;
        border: 1px solid #ededed;
        border-radius: 4px;
        position: relative;
        padding: 12px 4px;
        text-align: center;
        background-color: $white;
      }

      .ant-checkbox-wrapper {
        .ant-checkbox {
          opacity: 0;
          visibility: hidden;
          display: none;
        }

        .ant-checkbox-checked + span {
          .industry__wrapper {
            border: 3px solid $primary-color;
          }
        }
      }

      .industry__icon {
        img {
          height: 60px;
          width: auto;
        }
      }

      .industry__name {
        margin-top: 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #000000;
      }
    }
  }
}
