.product-information {
  .export-countries__tags {
    margin-bottom: 0px !important;

    ul {
      flex-wrap: wrap;

      li {
        .name {
          font-size: 14px !important;
        }

        svg {
          width: 14px;
          height: 14px;
          padding: 2px;
          fill: $white;
          background-color: $primary;
          border-radius: 50%;
        }
      }
    }
  }
}
