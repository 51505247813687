.companyHistory {

  .historyItem {
    padding: 12px 0;
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      left: 7px;
      top: 0;
      width: 3px;
      height: 100%;
      background: #004D40;
    }

    .wrapper {
      padding-left: 34px;
      position: relative;
      z-index: 2;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 18px;
        height: 18px;
        border: 3px solid #004D40;
        border-radius: 9px;
        background: #FFF;
      }
    }

    .year {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #004D40;
      margin-bottom: 6px;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #4F4F4F;
    }
  }
}
