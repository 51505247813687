.color-picker {
  max-width: 400px;
  padding: 12px;
  background: $white;
  border: 1px solid #ededed;
  border-radius: 4px;

  .react-colorful {
    width: 100%;

    &__saturation {
      border-radius: 0px;
    }
    &__hue {
      height: 12px;
      margin: 12px 0;
      border-radius: 0px;
    }
    &__saturation-pointer,
    &__hue-pointer {
      height: 24px;
      border-width: 4px;
    }
    &__saturation-pointer {
      width: 24px;
    }
    &__hue-pointer {
      width: 12px;
      border-radius: 4px;
    }
  }

  &__controller {

    > .col-3 {
      padding-right: 0;
    }

    .ant-input-prefix {
      color: #aeaeae;
    }

    &__format {
      border: none;

      .ant-select-selector {
        border: none !important;
      }
    }

    &__value {
      &--hex {
      }
      &--rgb {
        .row {
          gap: 32px;
          .col-3 {
            padding-left: 8px;
          }
        }
      }
    }
  }

  &__blocks {
    height: 58px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 12px 0;
    padding: 12px 0;
    border: 1px solid #ededed;
    border-right: none;
    border-left: none;

    overflow-x: scroll;

    &__block {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  &__footer {
    display: flex;
    gap: 12px;

    button {
      max-height: 32px;
    }
  }
}
