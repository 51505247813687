.anticon.anticon-info-circle.ant-dropdown-trigger.info-dropdown {
  position: absolute;
  top: 5px;
  left: 46%;
  z-index: 1000;
  color: #aeaeae;

  @media (max-width: 1220px) {
    left: 24%;
  }
  @media (max-width:580px) {
    left: 28%;
  }

  @media (min-width: 0px) and (max-width: 580px) {
    left: 44%;
  }
}
