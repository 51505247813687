.header-extras {
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
  gap: 12px !important;
  width: 160px !important;

  &__icon {
    width: 36px !important;
    height: 36px !important;
    display: flex !important;
    align-items: center;
    justify-content: center !important;
    position: relative;
    cursor: pointer;

    &__image {
      height: 20px;
    }

    &__badge__bell,
    &__badge__chat {
      background-color: #69f0ae;
      outline: 2px solid white;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      position: absolute;

      p {
        color: #ffff;
        font-size: 8px;
        font-weight: 700;
        margin-top: -2px;
        margin-left: 1px;
      }
    }

    &__badge__bell {
      top: 5px;
      left: 22px;
    }

    &__badge__chat {
      top: 5px;
      left: 25px;
    }
  }
}
