.marketplace-header {
  background: $primary-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
  margin-bottom: 28px;

  .header-logo {
    margin-right: 40px;

    img {
      height: 30px;
      width: auto;
    }
  }

  .header-right {
    margin-left: auto;

    a {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #FFFFFF;
      text-decoration: none;
    }
  }

  .header-menu {

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      li {
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: block;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 1.25px;
          text-transform: uppercase;
          color: #FFFFFF;
          text-decoration: none;
        }
      }
    }
  }
}
