.marketplaceCard {
  background: #FFFFFF;
  border: 1px solid #F7F7F7;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .title {
    padding: 20px 20px 0;
    margin-bottom: 4px;

    h2 {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.15px;
      color: #000000;
    }
  }

  .body {
    padding: 20px;
  }
}
