.ql-toolbar.ql-snow {
  border: 1px solid #CCCCCC;
  font-family: $base-font-family;
}

.quill {
  background: #FFF;
}

.ql-toolbar.ql-snow .ql-formats,
.ql-toolbar.ql-snow .ql-formats:focus,
.ql-toolbar.ql-snow .ql-formats .ql-size.ql-picker,
.ql-toolbar.ql-snow .ql-formats .ql-size.ql-picker:focus {
  outline: none !important;

  .ql-picker-label {
    outline: none !important;
  }
}

.ql-container.ql-snow {
  border: 1px solid #CCCCCC;
}

.ql-snow .ql-editor {
  min-height: 300px;

  img {
    max-width: 100%;
    height: auto;
  }

  h2,
  h3,
  h4,
  h5 {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  p,
  ul li,
  ol li,
  table tr td,
  th {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 400;
  }

  p,
  ul li,
  ol li {
    margin-bottom: 10px;
  }

  table tr td,
  table tr th {
    padding: 8px;
  }

  table tr th {
    font-weight: 600;
  }
}

.ql-editor .ql-size-small {
  font-size: 14px;
}

.ql-editor .ql-size-large {
  font-size: 18px;
}

.ql-editor .ql-size-huge {
  font-size: 21px;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: $primary;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: $primary;
}

.blocks-quill {
  .ql-toolbar.ql-snow {
    border-color: $border-color;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0;

    .ql-formats {
      margin: 0;

      button {
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 34px;
        display: flex;

        svg {
          height: 16px;
          width: auto;
          margin: 0 auto;
        }

        .ql-stroke {
          stroke: $page-title !important;
        }
      }

      .ql-picker-label {
        border: 0;
      }

      .ql-active {
        background: $border-color;
      }
    }
  }

  .ql-container.ql-snow {
    border-color: $border-color;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ql-snow .ql-editor {
    min-height: 134px;
  }
}
