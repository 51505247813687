.ant-drawer-body {
  padding: 40px 32px !important;

  .tutorial-list-drawer {
    position: relative !important;

    &__title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    &__button {
      position: absolute !important;
      bottom: 40px !important;
      max-width: 302px !important;
      width: 100% !important;
      max-height: 44px !important;
    }

    &__list {
      &__item, &__item--active {
        cursor: pointer;
        color: #4F4F4F;
        padding: 10px 20px;
        max-height: 40px;
      }

      &__item--active {
        background-color: #B4F7D6;
        color: #004D40;
        font-weight: 700;
      }
    }
  }
}
