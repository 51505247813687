.tag-list {
  list-style: none;
  margin-top: 8px;
  padding: 0px;

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 8px;
    background-color: #E0F2F1;
    color: $primary;

    svg {
      cursor: pointer;
    }
  }
}
