table {
  width: 100%;
  color: #000;
  margin-bottom: 1rem;

  thead {
    tr {
      border-top: 1px solid #d9dee9;
      border-bottom: 2px solid #d9dee9;

      th {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        padding: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        border-top: 1px solid #d9dee9;
        padding: 10px;
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;

        img {
          height: 60px !important;
          width: auto !important;
        }
      }

      .actions-td {
        &__wrapper {
          padding-right: 2rem;
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 0.5rem;
        }

        a, button {
          max-width: 0.9rem;
          padding: 0;
          margin: 0;
          height: auto;
          box-shadow: none !important;
          outline: none !important;
          background: transparent !important;
          border: 0;

          img,
          svg,
          i {
            max-width: 100%;
            width: 100%;
          }
        }
      }

      &:first-child {
        td {
          border-top: 0;
        }
      }
    }

    .master-tr {
      background: rgba(180, 247, 214, 0.3);
    }

    .variant-tr {
      background: rgba(180, 247, 214, 0.05);
    }
  }
}
