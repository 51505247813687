/*
----------------------------------------
  Box-model Mixins
----------------------------------------
*/

// Size Mixins
@mixin max-size($width, $height: $width) {
  max-width: $width;
  max-height: $height;
}

@mixin min-size($width, $height: $width) {
  min-width: $width;
  min-height: $height;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin circle($size) {
  @include size($size);
  border-radius: 50%;
}

@mixin square($size) {
  @include size($size);
}

// Display Mixins
@mixin flex($direction: row, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin grid($columns: 1fr, $rows: 1fr) {
  display: grid;
  grid-template-columns: $columns;
  grid-template-rows: $rows;
}

// Position Mixins
@mixin center($axis: 'both') {
  @if $axis == 'both' {
    @include flex(center, center);
  } @else {
    @if $axis == 'x' {
      @include flex(center);
    } @else {
      @include flex(null, center);
    }
  }
}

/*
  ----------------------------------------
  Grid Mixins
  ----------------------------------------
*/
@mixin grid-column($start, $end) {
  grid-column-start: $start;
  grid-column-end: $end;
}

@mixin grid-row($start, $end) {
  grid-row-start: $start;
  grid-row-end: $end;
}

@mixin grid-area($start-column, $end-column, $start-row, $end-row) {
  @include grid-column($start-column, $end-column);
  @include grid-row($start-row, $end-row);
}

/*
  ----------------------------------------
  Layout Mixins
  ----------------------------------------
*/
@mixin card(
  $padding: 1.2rem 2rem,
  $border: 1px solid #ededed,
  $background: $white,
  $radius: 4px
) {
  background-color: $background;
  padding: $padding;
  border: $border;
  border-radius: $radius;
}
