.product-tags {
  margin-top: 40px;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    li {
      margin-right: 8px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    .text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: #AEAEAE;
    }

    .tag {
      background: #E0F2F1;
      border-radius: 4px;
      padding: 3px 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #004D40;
    }
  }
}
