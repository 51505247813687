.title {
  color: #004d40;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
}

.info {
  font-size: 14px;
  margin-bottom: 20px;
}

.lists-container {
  display: flex;
  gap: 30px;
  min-width: 1095px;
  max-height: 452px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.lists-container::-webkit-scrollbar {
  display: none;
}
.lists-container {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &:has(.ant-spin) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
}

.services-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
}
