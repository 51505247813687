.verification-layout {
  position: relative;
  height: calc(100vh - 81px);
  z-index: 1;
  background: #f7f7f7;

  h1 {
    margin-top: 0;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: $primary;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
  }

  .layout-content {
    position: relative;
    min-height: 100vh;
    z-index: 2;
    background: #f7f7f7;

    .main-content {
      width: calc(100% - 450px);
      padding-bottom: 100px;
      background: #f7f7f7;

      .content-container {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 840px;
        padding: 0 15px;

        &__profile-link {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -0.28px;
          margin-bottom: 24px;

          a {
            color: $primary;
          }

          i {
            color: $primary;
            font-weight: 700;
            margin-right: 6px;
          }
        }
      }
    }

    .verification-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 420px;
      height: 100%;
      z-index: 3;
      background-color: $white;

      .nav-content {
        padding: 30px;
        user-select: none;
        cursor: default;

        .nav-progress {
          margin-bottom: 40px;

          h5 {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }

          span {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            color: $primary;
          }

          .ant-progress-circle-trail {
            stroke: #b2dfdb;
          }

          .ant-progress-inner:not(.ant-progress-circle-gradient)
            .ant-progress-circle-path {
            stroke: $primary-color;
          }

          .ant-progress-circle .ant-progress-text {
            color: $primary-color;
            font-weight: bold;
            font-size: 28px;
            line-height: 1;
          }

          .progress__description {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #000000;
            margin-left: 20px !important;
          }
        }

        .nav-steps {
          .steps__list {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
              display: flex;
              flex-wrap: nowrap;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              padding-bottom: 20px;
              position: relative;

              &:after {
                content: '';
                position: absolute;
                top: 34px;
                left: 15px;
                height: 20px;
                width: 4px;
                background: #ededed;
              }

              &:last-child {
                padding-bottom: 0;

                &:after {
                  display: none;
                }
              }

              .step__status {
                margin-right: 12px;
                width: 34px;
                border: 3px solid #ededed;
                border-radius: 28px;
                background: $white;
                display: flex;
                justify-content: center;
                align-items: center;

                .fe-check {
                  line-height: 28px;
                  color: $white;
                  font-weight: bold;
                  font-size: 18px;
                }
              }

              .step__name {
                a {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 20px;
                  letter-spacing: 0.25px;
                  color: $primary;
                  text-decoration: none;

                  &:hover {
                    color: lighten($primary, 10%);
                  }
                }
              }
            }

            .active {
              .step__status {
                border-color: $primary;
              }

              .step__name {
                a {
                  font-weight: 700;
                }
              }
            }

            .completed {
              .step__status {
                background: $primary;
                border-color: $primary;
              }
            }
          }
        }
      }
    }
  }

  .verification-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    box-shadow: 8px 0px 20px rgba(0, 0, 0, 0.12);
    padding: 15px 40px;
    z-index: 5;

    .ant-btn {
      height: 44px;
    }

    a {
      color: $primary-color;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
      letter-spacing: 0.25px;
    }

    &__navigation {
      a {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        padding: 10px 24px 10px 20px;
        border-radius: 4px;
        border: 2px solid $primary;
        background: $white;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        display: flex;
        align-items: center;
        gap: 10px;
        height: 40px;
      }

      button {
        padding: 0px 20px !important;
        display: flex;
        align-items: center;
        gap: 10px;
        line-height: 16px;
        letter-spacing: 1.25px;
        height: 40px !important;

        svg {
          margin-top: -1px;
        }
      }
    }
  }

  .hq-header {
    display: flex;
    justify-content: space-between;

    h4 {
      height: 24px;
    }

    .hq-header__stock {
      display: flex;
      align-items: flex-end;

      .ant-form-item {
        margin-bottom: 0px;
      }

      .ant-form-item-control-input {
        min-height: auto;
      }

      p {
        margin-left: 8px;
        display: inline;
      }
    }
  }

  .company-units {
    background: #f7f7f7;
    padding: 20px;
    border-radius: 4px;
  }

  .add-unit {
    margin-bottom: 0;

    button {
      border: none !important;
      padding: 0 !important;
      margin: 0 !important;
      outline: none !important;
      box-shadow: none !important;
      text-align: left;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #004d40;
      background-color: #f7f7f7;
    }
  }

  .company-logo {
    .image-preview {
      img {
        max-width: 100%;
        height: auto;
      }

      p {
        margin-bottom: 0;
        margin-top: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #808080;
      }
    }

    .logo__upload {
      width: 100%;
      height: 340px;
      border: 3px dashed #cccccc;
      box-sizing: border-box;
      border-radius: 5px;
      position: relative;

      &--on {
        background-color: $light-green-2;
      }

      input[type='file'] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        cursor: pointer;
      }

      p {
        position: absolute;
        width: 100%;
        max-width: 170px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #808080;

        span {
          font-weight: bold;
          color: #00968a;
        }
      }
    }
  }

  .brands-description {
    margin-bottom: 50px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #808080;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: bold;
        color: #000;
        font-size: 16px;
      }
    }
  }

  .brand-item {
    background: $white;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 20px;
    position: relative;

    .image-circle {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      overflow: hidden;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .brand-logo-upload {
      margin-bottom: 20px;
    }

    .brand-logo-name {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #000000;

      span {
        z-index: 100;
      }

      i {
        cursor: pointer;
        color: $gray-9;
        font-size: 18px;
      }
    }

    .upload-link {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #004d40;
      position: relative;
      cursor: pointer;

      input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        cursor: pointer;
      }
    }

    .text-sm-end {
      color: #aeaeae;
      margin-top: -16px;
    }

    .timeline-remove {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 10px;

      svg {
        color: #aeaeae;
      }
    }
  }

  .notifications-settings {
    margin-bottom: 28px;
    strong {
      margin-top: 28px;
      margin-bottom: 20px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
    }

    .ant-radio-wrapper {
      margin-right: 64px;

      input:hover {
        outline: $primary;
      }
      input:focus {
        outline: $primary;
      }
    }
  }

  .company__industries {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: center;
    width: calc(100% + 40px);
    margin-left: -10px;

    .industry-item {
      @include size(151px, 144px);
      margin: 4px;
      user-select: none;
      cursor: pointer;

      .industry__wrapper {
        @include card(1.2rem 0.6rem);
        @include size(151px, 144px);
        position: relative;
        text-align: center;
        transition: box-shadow 0.3s ease;

        &:hover {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
      }

      .ant-checkbox-wrapper {
        .ant-checkbox {
          opacity: 0;
          visibility: hidden;
          display: none;
        }

        .ant-checkbox-checked + span {
          .industry__wrapper {
            border: 3px solid $primary-color;
          }
        }
      }

      .industry__icon {
        img {
          height: 60px;
          width: auto;
        }
      }

      .industry__name {
        margin-top: 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #000000;
      }
    }
  }

  .sub-industries__wrapper {
    .sub-industries {
      &__tab {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: $gray-9;
        cursor: pointer;
        padding: 0 20px 12px 0;
        margin-right: 20px;
        max-width: 95px;
        width: fit-content;
        align-self: flex-start;
        border-bottom: solid 4px transparent;
        @include transition-middle;

        &--active {
          color: $black;
          border-bottom: solid 4px $primary;
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: #ededed;
        margin-bottom: 30px;
      }

      .sub-industries__check {
        margin-bottom: 10px;
        @include transition-fast;

        &-all {
          font-weight: 700;
        }
      }

      &__show-all {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 20px;
        width: fit-content;

        p {
          font-size: 16px;
          line-height: 24px;
          color: $primary;
          margin: 0;
        }

        i {
          font-size: 18px;
          color: $primary;
        }
      }
    }

    .industry__item {
      padding-bottom: 20px;
      margin-bottom: 20px;

      &:last-child {
        border-bottom: 0;
      }

      .industry__label {
        img {
          width: 60px;
          height: 60px;
        }

        .industry__name {
          margin-top: 12px;
          margin-bottom: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #000000;
        }
      }
    }
  }

  .additional-info-gallery {
    .gallery__wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .gallery__item {
        width: 140px;
        height: 140px;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid #ededed;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        cursor: pointer;

        &__overlay {
          background-color: $black;
          opacity: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 100000;

          &--active {
            opacity: 0.6 !important;
          }

          .gallery__item__remove {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 16px;
            color: $white;
            cursor: pointer;
            z-index: 100;
            opacity: 1 !important;
            font-size: 24px;
          }
        }
        img {
          height: 140px;
          width: auto;
        }
      }

      .gallery__upload {
        position: relative;
        z-index: 1;
        cursor: pointer;

        input {
          opacity: 0;
          z-index: 3;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        p {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: $primary-color;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .upload-docs {
    margin-top: 20px;
    padding: 20px;
    background: $white;
    border-radius: 4px;

    h2 {
      margin-top: 0;
      margin-bottom: 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #000000;
    }

    .docs__uploader {
      .uploader__radio {
        margin-bottom: 20px;
      }

      .uploader__btn {
        margin-top: 20px;
        border-radius: 4px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #ffffff;
        background: $primary-color;
        border-color: $primary-color;
        cursor: pointer !important;
      }

      .uploader__btn:disabled {
        background: #cccccc;
        border-color: #cccccc;
        color: #fff;
      }

      .uploader__upload {
        .upload__placeholder {
          position: relative;
          z-index: 1;
          margin: 8px 0;

          input {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
            cursor: pointer !important;
          }

          span {
            position: relative;
            z-index: 2;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #808080;
            cursor: pointer;

            strong {
              z-index: 2;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.1px;
              color: #004d40;
              padding-right: 8px;
              cursor: pointer;
            }
          }

          .uploaded {
            .anticon {
              padding-left: 8px;
              font-size: 16px;
            }
          }
        }
      }
    }

    .docs__list {
      margin-top: 20px;

      .doc__item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        background: #f7f7f7;
        border-radius: 4px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        .doc-icon {
          width: 32px;
          height: 32px;
          background: #80cbc5;
          border-radius: 32px;
          position: relative;
          margin-right: 12px;

          .anticon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
            color: #fff;
          }
        }

        .doc-name {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #000000;
          margin-right: auto;
        }

        .doc-remove {
          margin-left: 140px;
          font-size: 18px;

          &:hover {
            color: $danger-color;
          }
        }
      }
    }
  }
}

.success-screen__wrapper {
  height: 100vh;
  position: relative;

  .success-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    text-align: center;

    .screen__logo {
      margin-bottom: 30px;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    h1 {
      margin-top: 0;
      margin-bottom: 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      color: #000000;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #000000;
    }
  }
}

.sales-turnover {
  &__export {
    display: flex;
    align-items: center;

    span {
      font-weight: 700;
      margin-left: 8px;
    }
  }
}

.financial {
  &__payments {
    &__cia-methods {
      margin-left: 28px;
    }

    &__check {
      margin-bottom: 10px;
      @include transition-fast;

      &-all {
        font-weight: 700;
      }
    }

    &__disclaimer {
      margin-top: 20px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0px;

      a {
        font-weight: 600;
        color: $primary;
        text-decoration: underline;
      }
    }
  }
}

.channels {
  &__controller {
    display: flex;
    align-items: flex-start;

    .col-2 {
      padding: 0px;
    }

    &__btn {
      margin-left: 12px;
      svg {
        border-radius: 8px;
        color: $primary;
        height: 100%;
        width: 40px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &__card {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 6px 8px;
      border-radius: 4px;
      background: #e0f2f1;

      p {
        margin: 0px;
        color: $primary;
        font-size: 14px;
        line-height: 20px;
      }

      .anticon-close {
        cursor: pointer;
        background-color: $primary;
        color: $white;
        border-radius: 50%;
        font-size: 8px;
        padding: 3px;
        font-weight: 700;
      }
    }
  }
}
