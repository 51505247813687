body {
  font-family: 'Open Sans', Roboto, Arial, sans-serif;

  #root {
    min-height: 100%;
  }
}

.container {
  display: block;
  width: 100%;
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1370px;
  }
}

@media (min-width: 1900px) {
  .container {
    max-width: 1570px;
  }
}

.container-max-1920 {
  max-width: 1920px;
}

.anticon {
  vertical-align: 1px;
}

.ant-message-error .anticon {
  color: $danger-color;
}

.ant-message-info .anticon, .ant-message-loading .anticon {
  color: $primary-color;
}

.page-title {
  margin-top: 0;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #004D40;
}

.success-color {
  color: $success;

  &:hover {
    color: darken($success, 5%);
  }
}

.danger-color {
  color: $danger-color;
}
