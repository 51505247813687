.brands-list {
  margin-bottom: 40px;
  margin-top: 24px;

  .brand {
    border: 1px solid #EDEDED;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 16px;
    padding: 6px 8px;
    background: #F7F7F7;

    .brand-image {
      height: 169px;
      overflow: hidden;
      position: relative;
      margin-bottom: 8px;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: auto;
      }
    }

    .brand-name {
      margin-top: 0;
      margin-bottom: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #000000;
      height: 20px;
      overflow: hidden;

      a {
        color: #000000;
        outline: none;
        text-decoration: none;

        &:hover {
          color: #00968A;
        }
      }
    }

    p {
      margin-bottom: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #00968A;

      img {
        height: 16px;
        width: 16px;
        margin-right: 4px;
        margin-top: -4px;
      }
    }
  }
}

.sellers-list {
  margin-bottom: 40px;
  margin-top: 24px;

  .seller {
    border: 1px solid #EDEDED;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 16px;
    padding: 6px 8px;

    .seller-image {
      height: 169px;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: auto;
      }
    }

    .sellers-badges {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;

      .rate {

        svg {
          height: 16px;
          width: 16px;
        }
      }

      .badges {

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {

            img {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }

    .seller-name {
      margin-top: 8px;
      margin-bottom: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      height: 20px;
      overflow: hidden;

      a {
        color: #000000;
        outline: none;
        text-decoration: none;

        &:hover {
          color: #00968A;
        }
      }
    }

    .description {

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #808080;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
