.products {
  flex-basis: calc(100% - 320px);
  margin-top: 40px;

  .products__list {
    margin-top: 24px;

    .item-supplier {

      .prices {

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #000000;
        }
      }

      .supplier {
        flex-basis: calc(100% - 64px);
        padding-right: 8px;
        overflow: hidden;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.4px;
        color: #00968A;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .icons {

        ul {
          padding: 0;
          list-style-type: none;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: -7px 0 0;

          li {
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
            }

            img {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }

    .product-item__list {
      padding: 12px;
      border: 1px solid #EDEDED;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-bottom: 24px;
      margin-bottom: 12px;
      transition: all .3s ease;

      .item-image {
        flex-basis: 165px;
        min-width: 165px;
        height: 231px;
        position: relative;
        overflow: hidden;
        margin-right: 28px;
        transition: all .3s ease;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          max-width: 100%;
          height: auto;
          transform: translate(-50%, -50%);
        }
      }

      .product-data {
        margin: 12px 0;
        flex-basis: calc(100% - 468px);
        padding-right: 24px;
        border-right: 1px solid #EDEDED;
        transition: all .3s ease;

        .product-name {
          margin-top: 0;
          margin-bottom: 16px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          transition: all .3s ease;

          a {
            color: #000000;
            outline: none;
            text-decoration: none;

            &:hover {
              color: #00968A;
            }
          }
        }

        .description {
          height: 60px;
          overflow: hidden;

          p, ul li, ol li {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #808080;
            transition: all .3s ease;
          }
        }

        .learn-more {
          margin-top: 8px;

          a {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #004D40;
            outline: none;
            text-decoration: none;
            transition: all .3s ease;
          }
        }
      }

      .product-prices {
        margin: 12px 12px 12px 0;
        padding-left: 24px;
        flex-basis: 255px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all .3s ease;

        .prices {
          transition: all .3s ease;

          span {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.15px;
            color: #000000;
          }
        }

        .min-order {
          margin-top: 8px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.4px;
          color: #00968A;
          transition: all .3s ease;
        }

        .choose-options {
          transition: all .3s ease;

          a {
            display: block;
            outline: none;
            text-decoration: none;
            padding: 8px;
            width: 100%;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            color: #004D40;
            border: 2px solid #004D40;
            border-radius: 4px;
            text-align: center;
            transition: all .2s ease;

            &:hover {
              background: #004D40;
              color: #ffffff;
            }
          }
        }
      }
    }

    .product-item {
      padding: 8px;
      border: 1px solid #EDEDED;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-bottom: 24px;
      transition: all .3s ease;

      .item-image {
        height: 272px;
        width: 100%;
        position: relative;
        overflow: hidden;
        margin-bottom: 8px;
        transition: all .3s ease;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 100%;
          max-height: 272px;
          width: auto;
          transform: translate(-50%, -50%);
        }
      }

      .product-name {
        margin-top: 4px;
        margin-bottom: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        height: 40px;
        overflow: hidden;
        transition: all .3s ease;

        a {
          color: #000000;
          outline: none;
          text-decoration: none;

          &:hover {
            color: #00968A;
          }
        }
      }

      .min-order {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #00968A;
        margin-bottom: 0;
        transition: all .3s ease;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1339px) {
  .products .products__list .product-item .item-image {
    height: 169px;
  }
}

@media (min-width: 1340px) and (max-width: 1700px) {
  .products .products__list .product-item .item-image {
    height: 219px;
  }
}
