a {
  color: #000;

  &:hover {
    color: $primary-color;
  }
}

.text-link {
  font-size: 16px;
  font-weight: 700;
  color: $primary;

  &-sm {
    font-size: 14px;
  }

  &-lg {
    font-size: 20px;
  }
}
