.default-page-title {
  margin-top: 0;
  margin-bottom: 25px;
  color: $primary-color;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
}
