.companyLogo {
  width: 160px;
  height: 160px;
  display: block;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border: 1px solid #D8D8D8;
  border-radius: 80px;

  img {
    max-width: 80%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.companyName {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 36px;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #004D40;
}

.companyDetails {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
    margin-bottom: 24px;
  }

  h4 {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #004D40;
  }

  .industries {

    .industry {
      margin-top: 20px;

      .icon {
        margin-bottom: 16px;

        img {
          height: 60px;
          width: auto;
        }
      }

      .name {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        min-height: 40px;
      }
    }
  }
}
