.product-gallery {
  height: 545px;
  overflow: hidden;
  position: relative;

  .ant-carousel .slick-slider .slick-track, .ant-carousel .slick-slider .slick-list {
    text-align: center;
  }

  .ant-carousel {

    img {
      height: 545px;
      width: auto;
    }
  }

  .ant-carousel .slick-dots li {
    width: 10px;
    height: 10px;
    background: transparent;
  }

  .ant-carousel .slick-dots li button {
    display: block;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    border: 1px solid #EDEDED;
    background: transparent;
    opacity: 1;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: $primary-color;
    border: 1px solid #FFF;
    opacity: 1;
  }
}
