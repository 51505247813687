@mixin primaryBold {
  font-weight: 700;
  color: $primary-color;
}

.company-identity {
  &__header {
    &__title {
      margin: 0;
      font-size: 20px;
      @include primaryBold();
    }
    &__description {
      margin-top: 24px;
      margin-bottom: 28px;
      &__link {
        @include primaryBold();
      }
    }
  }
  &__section {
    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 44px;
      grid-row-gap: 8px;
      grid-auto-flow: column;

      &--fonts {
        grid-auto-flow: row;
        grid-template-rows: auto;
        margin-bottom: 28px;
      }
    }

    &__title {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 700;
      color: $gray;
    }
    &__controller {
      padding: 12px;
      border: 1px solid #ede;
      border-radius: 4px;
      background-color: $white;

      &__title,
      &__description {
        font-size: 14px;
        margin-bottom: 4px;
      }

      &__title {
        &--font {
          margin-left: -24px;
          font-size: 16px;
        }
      }

      &__description {
        color: $gray-9;
      }

      &__color {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 8px;

        &__preview {
          width: 40px;
          height: 40px;
          border: 1px solid #ede;
          border-radius: 4px;
        }

        svg {
          color: #aeaeae;
        }
      }

      &__font-preview {
        p {
          max-width: 68px;
          margin-bottom: 0;
          font-size: 32px;
          border-right: 1px solid #ede;
          // text-align: center;
        }
      }
    }
  }

  &__footer {
    margin-top: 28px;

    button {
      height: 44px;
      margin-right: 16px;
      padding-inline: 80px;
    }
  }
}
